<template >
  <v-footer class="dark" padless style="margin-top: 20px">
    <v-row justify="center" no-gutters>
      <!-- <v-btn color="" text rounded class="my-2"> Contact Us </v-btn> -->
      <v-col class="py-4 text-center dark--text" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>Infinity Sports AI Beta</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>