import Vue from 'vue'
import Axios from 'axios'

Axios.defaults.baseURL = process.env.VUE_APP_BASEURL_API;

Vue.prototype.$baseURL = process.env.VUE_APP_BASEURL;

Vue.prototype.$http = Axios


Vue.mixin({
    methods: {

        Login(email, password) {
            return new Promise((resolve, reject) => {
                let bodyFormData = new FormData();
                bodyFormData.append("username", email);
                bodyFormData.append("password", password);
                this.$http({
                    method: "post",
                    url: "/auth/login",
                    data: bodyFormData,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    withCredentials: true,
                }).then((response)=>{
                    resolve(true)
                }).catch((error) => {
                    reject(error.response.data.detail)
                })
            })
        },
        isLoggedIn() {
            return new Promise((resolve, reject) => {
                this.$http.get("/user/logged", {
                    withCredentials: true
                }).then((response) => {
                    resolve(true)
                }).catch((error) => {
                    reject(false)
                })
            })
        },
        getUser() {
            return new Promise((resolve, reject) => {
                this.$http.get("/user/", {
                    withCredentials: true
                }).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(null)
                })
            })
        },
        Logout() {
            return new Promise((resolve, reject) => {
                this.$http({
                        method: "post",
                        url: "/auth/logout",
                        withCredentials: true,
                    })
                    .then((response) => {
                        location.reload();
                        resolve(true)
                    }).catch((error) => {
                        reject(false)
                    })
            })
        },
        billingPortal() {
            this.$http
              .get("/billing/portal", {
                baseURL: this.$baseURL,
                withCredentials: true,
              })
              .then((response) => {
                // Create a new anchor element with the billing portal URL
                const billingPortalLink = document.createElement('a');
                billingPortalLink.href = response.data.url;
          
                // Simulate a click on the anchor element to open the URL in a new tab
                billingPortalLink.rel = 'noopener noreferrer';
                billingPortalLink.click();
              });
          },
    }

})