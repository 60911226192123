<template>
  <v-app>

    <v-navigation-drawer v-model="drawer" temporary fixed>
      <v-list nav dense>
        <v-list-item-group active-class="active-nav">
          <v-list-item to="/">
            <v-list-item-content>
              <v-list-item-title class="text-h6 mb-2">
                <v-img
                  class="logo"
                  src="@/assets/logo.svg"
                  max-height="120"
                  max-width="300"
                  contain
                ></v-img>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="hidden-sm-and-up" to="login">
            <v-list-item-icon>
              <v-icon>mdi-login-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item>
          <v-list-item class="hidden-sm-and-up" to="join">
            <v-list-item-icon>
              <v-icon>mdi-plus-box</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Join</v-list-item-title>
          </v-list-item>
          <v-list-item href="https://www.infinitysports.ai" target="_blank">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item to="/plans">
            <v-list-item-icon>
              <v-icon>mdi-format-list-bulleted-type</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Plans</v-list-item-title>
          </v-list-item>
          <v-list-item
            href="https://api.infinitysports.ai/docs"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon>mdi-api</v-icon>
            </v-list-item-icon>
            <v-list-item-title>API</v-list-item-title>
          </v-list-item>
          <v-list-item
            href="https://www.infinitysports.ai/contact"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon>mdi-help-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <router-link to="/">
        <v-img
          class="ml-2 mr-2 logo hidden-sm-and-down"
          src="@/assets/logo.svg"
          max-height="120"
          max-width="300"
          contain
        ></v-img>
        <v-img
          class="hidden-md-and-up"
          src="@/assets/logogram.svg"
          max-height="80"
          max-width="80"
          contain
        ></v-img>
      </router-link>

      <v-spacer></v-spacer>

      <AccountDropDown :key="$route.fullPath" />
      <template
        v-slot:extension
        v-if="this.$route.name === 'Home' || this.$route.name === 'History'"
      >
        <v-tabs center v-model="tab" class="tab" grow>
          <v-tab>
            <v-icon class="mr-2">mdi-basketball-hoop</v-icon
            ><span class="d-none d-sm-flex">Upcoming Matches</span>
          </v-tab>
          <v-tab
            ><v-icon class="mr-2">mdi-history</v-icon>
            <span class="d-none d-sm-flex">Previous Matches</span>
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-main>
      <router-view :tab="tab" />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer";
import AccountDropDown from "@/components/AccountDropDown";

export default {
  name: "App",
  components: { Footer, AccountDropDown },
  data: () => ({
    links: ["Upcoming Matches", "Previous Matches"],
    tab: 0,
    items: ["Upcoming Matches", "Previous Matches"],
    drawer: false,
  }),
  mounted() {
    // if (this.$route.name === "History") {
    //   this.tab = 1;
    // }
  },
  watch: {
    tab() {
      window.scroll(0, 0);
    },
  },
};
</script>


<style scoped>
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .tab {
    padding: 0;
  }
}

@media (min-width: 1025px) {
  .tab {
    padding: 0px 100px 0px 100px;
  }
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

.logo {
  display: block;
  margin: auto;
}

.active-nav {
  color: #ae0000;
}
</style>