<template>
  <div v-if="!loggedIn">
    <div class="d-none d-sm-flex">
      <v-btn color="secondary" class="mx-2" to="/login" v-if="this.$route.name !== 'Login'"
        ><v-icon class="mr-2">mdi-login-variant</v-icon> Login
      </v-btn>
      <v-btn class="primary" to="/join" v-if="this.$route.name !== 'Register'"
        ><v-icon class="mr-2">mdi-plus-box</v-icon> Join
      </v-btn>
    </div>
    <div class="hidden-sm-and-up">
      <v-btn color="secondary" text to="/login" v-if="this.$route.name !== 'Login'"
        ><v-icon>mdi-login-variant</v-icon>
      </v-btn>
      <v-btn color="secondary" text to="/join" v-if="this.$route.name !== 'Register'"
        ><v-icon>mdi-plus-box</v-icon>
      </v-btn>
    </div>
  </div>
  <div v-else>
    <v-btn style="margin: 0px" class="overline font-weight-bold" text>{{
      user.plan
    }}</v-btn>

    <v-menu bottom min-width="200px" rounded offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon x-large v-on="on">
          <v-avatar color="primary" size="38">
            <span class="white--text text-h6 text-uppercase">{{
              user.initials
            }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <v-avatar color="primary">
              <span class="white--text text-h5 text-uppercase">{{
                user.initials
              }}</span>
            </v-avatar>
            <h3 class="text-capitalize">{{ user.fullName }}</h3>
            <p class="text-caption mt-1">
              {{ user.email }}
            </p>
            <v-divider class="my-3"></v-divider>
            <EditAccount
              :email="user.email"
              :first_name="user.first_name"
              :last_name="user.last_name"
            />
            <v-divider class="my-3"></v-divider>
            <v-btn
              depressed
              rounded
              text
              @click="billingPortal()"
              v-if="!freePlan"
            >
              Manage Billing
            </v-btn>
            <v-btn depressed rounded text v-else to="/plans"
              ><v-icon class="mr-2">mdi-chevron-up-circle</v-icon>UPGRADE
            </v-btn>

            <v-divider class="my-3"></v-divider>
            <v-btn
              depressed
              rounded
              text
              @click="
                Logout();
                this.loadingLogout = true;
              "
              :loading="loadingLogout"
            >
              Sign out
            </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import EditAccount from "@/components/EditAccount";

export default {
  name: "AccountDropDown",
  components: { EditAccount },
  data: () => ({
    user: {
      initials: "",
      fullName: "",
      email: "",
      plan: "",
      first_name: "",
      last_name: "",
    },
    loggedIn: false,
    loadingLogout: false,
    freePlan: false,
  }),
  created() {
    this.isLoggedIn()
      .then(() =>
        this.getUser().then((data) => {
          this.user.first_name = data.info.first_name;
          this.user.last_name = data.info.last_name;
          this.user.fullName = data.info.first_name + " " + data.info.last_name;
          this.user.email = data.info.email;
          this.user.initials = data.info.first_name[0] + data.info.last_name[0];
          if (data.subscription.plan) {
            this.user.plan = data.subscription.plan.name;
          } else {
            this.user.plan = "Rookie Plan";
            this.freePlan = true;
          }
          this.loggedIn = true;
        })
      )
      .catch((error) => {
        this.loggedIn = false;
      });
  },
};
</script>