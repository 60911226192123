<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog transition="dialog-bottom-transition" max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed rounded text v-bind="attrs" v-on="on"
            >Edit Account</v-btn
          >
        </template>
        <template v-slot:default="dialog">
          <v-alert
            type="success"
            v-if="updated"
            dismissible
            transition="scale-transition"
            >Your profile has been updated!</v-alert
          >

          <v-card>
            <v-form v-model="valid">
              <v-overlay :value="loading">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
              <v-toolbar color="primary" dark>Edit Account</v-toolbar>
              <v-card-text style="margin-top: 20px">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="First name"
                      v-model="new_first_name"
                      :rules="nameRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Last name"
                      v-model="new_last_name"
                      :rules="nameRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="E-mail"
                      :value="email"
                      disabled
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="justify-start">
                <v-btn
                  class="primary"
                  :to="{ name: 'Forgot', params: { emailFromLogin: email } }"
                  >Reset Password</v-btn
                >
                <v-btn
                  class="primary"
                  @click="
                    billingPortal();
                  "
                  >Manage Billing</v-btn
                >
              </v-card-actions>
              <v-card-actions class="justify-start">
                <v-btn class="primary" to="/plans">Switch Plans</v-btn>
              </v-card-actions>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Close</v-btn>
                <v-btn text @click="updateUser" :disabled="!valid || !isNew"
                  ><v-icon class="mr-1">mdi-content-save</v-icon>Save</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EditAccount",
  props: ["email", "first_name", "last_name"],
  data: () => ({
    updated: false,
    loading: false,
    new_first_name: "",
    new_last_name: "",
    valid: false,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 30 || "Name must be less than 30 characters",
    ],
  }),
  mounted() {
    this.new_first_name = this.first_name;
    this.new_last_name = this.last_name;
  },
  methods: {
    updateUser() {
      this.loading = true;
      this.$http({
        method: "put",
        url: "/user/update",
        data: {
          first_name: this.new_first_name,
          last_name: this.new_last_name,
        },
        withCredentials: true,
      })
        .then((response) => {
          this.loading = false;
          this.updated = true;
          setTimeout(() => {
            this.updated = false;
            location.reload();
          }, 1000);
        })
        .catch((error) => {});
    },
  },
  computed: {
    isNew() {
      return (
        this.new_first_name !== this.first_name ||
        this.new_last_name !== this.last_name
      );
    },
    isNotEmpty() {
      return this.new_first_name.length > 0 && this.new_last_name.length > 0;
    },
  },
};
</script>